/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { StatusScreen } from '@mercadopago/sdk-react';
import { infoPix } from '../../../services/updateStatusPlan';

export const PaymentStatusComponent = ({
  paymentId,
  planUserId,
  qrCode,
  qrCodeBase64,
  onSuccess,
}) => {
  const settings = {
    initialization: {
      paymentId, // Valor do pagamento
    },
  };

  const findPix = () => {
    setTimeout(() => {
      infoPix(paymentId, planUserId, onSuccess, findPix);
    }, 2000);
  };

  useEffect(() => {
    findPix();
  }, []);

  return <StatusScreen initialization={settings.initialization} />;
};
