/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Payment } from '@mercadopago/sdk-react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { onError } from '../../../../../utils/scripts';
import { createMercadoPagoPayment } from '../../../services/updateStatusPlan';

export const PaymentBrickComponent = ({
  publicKey,
  amount,
  planUserId,
  planName,
  planDescription,
  onSubmit,
  onSuccess,
  onFail,
}) => {
  const settings = {
    initialization: {
      amount, // Valor do pagamento
    },
    callbacks: {
      onReady: () => {
        console.log('Payment Brick is ready');
      },
      onError: async error => {
        console.error('Payment Brick error:', error);
        await onFail();
      },
      onSubmit: async paymentData => {
        onSubmit();
        const {
          formData: {
            installments,
            payment_method_id,
            token,
            payer: { email, identification },
            transaction_amount,
            issuer_id,
          },
        } = paymentData;

        const submitData = {
          installments,
          payment_method_id,
          token,
          email,
          transaction_amount,
          planUserId,
          issuer_id,
          identification,
          planName,
          planDescription,
        };

        const response = await createMercadoPagoPayment(submitData, onError);
        const { status, data } = response;
        const isPix = payment_method_id === 'pix' ? true : false;
        const paymentId = data?.paymentId || null;
        const qrCode = data?.qr_code || '';
        const qrCodeBase64 = data?.qr_code_base64 || '';

        if (status === 201 || status === 200) {
          onSuccess(paymentId, isPix, qrCode, qrCodeBase64);
          return;
        }

        onFail();
        return;
        // Lógica para processar o pagamento
      },
    },
  };

  useEffect(() => {
    initMercadoPago(publicKey);
  }, []);

  return (
    <Payment
      initialization={settings.initialization}
      onSubmit={settings.callbacks.onSubmit}
      onError={settings.callbacks.onError}
      onReady={settings.callbacks.onReady}
      customization={{
        paymentMethods: {
          creditCard: 'all',
          bankTransfer: 'all',
        },
      }}
    />
  );
};
