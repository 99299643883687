import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { inject, observer } from 'mobx-react';
import { Container } from '../components/Container';
import { GridCell } from '../components/GridCell';
import { Layout } from '../components/Layout';
import { LoaderFullPage } from '../components/Loaders';
import { ModalSuccessAndError } from '../components/ModalSuccessAndError';
import { Topbar } from '../components/Topbar';
import { UserData } from '../modules/Login/components/SignIn/store/user';
import { PaymentBrickComponent } from '../modules/SubscriptionPlans/components/PlanCards/PlanCard/PaymentBrick';
import { PaymentStatusComponent } from '../modules/SubscriptionPlans/components/PlanCards/PlanCard/PaymentStatus';
import { SafeAreaFooter } from '../modules/SubscriptionPlans/components/SafeAreaFooter';
import { TypeStorePlan } from '../modules/SubscriptionPlans/store/selectedPlan';

interface IUser {
  profile?: UserData;
}

interface Props {
  selectedPlan?: TypeStorePlan;
  user?: IUser;
}

const ProcessarPagamento = inject(
  'selectedPlan',
  'user',
)(
  observer(({ user, selectedPlan }: Props) => {
    const [isPix, setIsPix] = useState(false);
    const [paymentId, setPaymentId] = useState(null);
    const [qrCodeBase64, setQrCodeBase64] = useState(null);
    const [qrCode, setQrCode] = useState(null);
    const [userType, setUserType] = useState('');
    const [planId, setPlanId] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [requestStatus, setRequestStatus] = useState(false);
    const [modalContent, setModalContent] = useState('Ocorreu um erro na sua solicitação');
    const [modalTitle, setModalTitle] = useState('Tente novamente mais tarde');

    const handleNavigation = () => {
      navigate('/meus-anuncios');
    };

    const toggleModalSuccessError = () => {
      setModalOpen(!isModalOpen);
      handleNavigation();
    };

    useEffect(() => {
      if (requestStatus) {
        setModalContent('Plano ativado com sucesso!');
        setModalTitle('Parabéns');
        return;
      }
      setModalContent('Falha ao processar seu pagamento!');
      setModalTitle('Verifique os dados do seu cartão ou tente novamente mais tarde;');
    }, [requestStatus]);

    useEffect(() => {
      if (user && user.profile && !user.profile.admin && user.profile.type) {
        setUserType(user.profile.type === 1 ? 'regular' : 'company');
      }
    }, []);

    useEffect(() => {
      const location = window !== undefined ? window.location : null;
      const params = new URLSearchParams(location?.search);
      setPlanId(params.get('planId'));
    }, []);

    const onSubmit = () => {
      setLoading(true);
    };

    const onFail = () => {
      setRequestStatus(false);
      setLoading(false);
      setModalOpen(true);
    };

    const onSuccess = (paymentId, isPix, qrCode, qrCodeBase64) => {
      if (isPix) {
        setPaymentId(paymentId);
        setIsPix(isPix);
        setQrCode(qrCode);
        setQrCodeBase64(qrCodeBase64);
        setLoading(false);
        return;
      }
      setRequestStatus(true);
      setLoading(false);
      setModalOpen(true);
    };

    const onPixSuccess = () => {
      setRequestStatus(true);
      setModalOpen(true);
    };

    return isLoading ? (
      <LoaderFullPage />
    ) : (
      <Layout>
        <Topbar />
        <section className="bg-wild-sand pv32 ph16 ph0-ns flex justify-center">
          <GridCell width={[1, 730, 970, 1280]}>
            {isPix ? (
              <PaymentStatusComponent
                paymentId={paymentId}
                planUserId={planId}
                qrCode={qrCode}
                qrCodeBase64={qrCodeBase64}
                onSuccess={onPixSuccess}
              />
            ) : (
              <PaymentBrickComponent
                amount={selectedPlan.plan.price}
                publicKey={'APP_USR-b1622ee8-bc8d-4466-9233-bf6309e85e59'}
                planUserId={planId}
                onSubmit={onSubmit}
                onFail={onFail}
                onSuccess={onSuccess}
                planName={selectedPlan.plan.title}
                planDescription={selectedPlan.plan.reference}
              />
            )}
          </GridCell>
        </section>
        <Container className="mt32">
          <SafeAreaFooter userType={userType} />
        </Container>
        <ModalSuccessAndError
          title={modalTitle}
          subTitle={modalContent}
          isOpen={isModalOpen}
          success={requestStatus}
          closeModal={toggleModalSuccessError}
          textBtnClose="Ok"
        />
      </Layout>
    );
  }),
);

export default ProcessarPagamento;
